var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from "react";
import classNames from "classnames";
export const svgBaseProps = {
    width: "1em",
    height: "1em",
    fill: "currentColor",
    ["aria-hidden"]: "true",
    focusable: "false"
};
const customCache = new Set();
const Icon = props => {
    const { 
    // affect outter <i>...</i>
    className, 
    // affect inner <svg>...</svg>
    type, component: Component, viewBox, spin, 
    // children
    children } = props, restProps = __rest(props, ["className", "type", "component", "viewBox", "spin", "children"]);
    const classString = classNames({
        [`anticon`]: true,
        [`anticon-${type}`]: Boolean(type)
    }, className);
    const svgClassString = classNames({
        [`anticon-spin`]: !!spin || type === "loading"
    });
    let innerNode;
    // component > children > type
    if (Component) {
        const innerSvgProps = Object.assign({}, svgBaseProps, { className: svgClassString, viewBox });
        if (!viewBox) {
            delete innerSvgProps.viewBox;
        }
        innerNode = React.createElement(Component, Object.assign({}, innerSvgProps), children);
    }
    if (children) {
        const innerSvgProps = Object.assign({}, svgBaseProps, { className: svgClassString });
        innerNode = (React.createElement("svg", Object.assign({}, innerSvgProps, { viewBox: viewBox }), children));
    }
    return (React.createElement("i", Object.assign({}, restProps, { className: classString }), innerNode));
};
function createFromIconfontCN(options = {}) {
    const { scriptUrl, extraCommonProps = {} } = options;
    /**
     * DOM API required.
     * Make sure in browser environment.
     * The Custom Icon will create a <script/>
     * that loads SVG symbols and insert the SVG Element into the document body.
     */
    if (typeof document !== "undefined" &&
        typeof window !== "undefined" &&
        typeof document.createElement === "function" &&
        typeof scriptUrl === "string" &&
        scriptUrl.length &&
        !customCache.has(scriptUrl)) {
        const script = document.createElement("script");
        script.setAttribute("src", scriptUrl);
        script.setAttribute("data-namespace", scriptUrl);
        customCache.add(scriptUrl);
        document.body.appendChild(script);
    }
    const Iconfont = props => {
        const { type, children } = props, restProps = __rest(props, ["type", "children"]);
        // component > children > type
        let content = null;
        if (props.type) {
            content = React.createElement("use", { xlinkHref: `#${type}` });
        }
        if (children) {
            content = children;
        }
        return (React.createElement(Icon, Object.assign({}, restProps, extraCommonProps), content));
    };
    Iconfont.displayName = "Iconfont";
    return Iconfont;
}
export default createFromIconfontCN;
