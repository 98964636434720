import * as React from "react";
import classNames from "classnames";
import "./index.css";
class RbImage extends React.Component {
    constructor() {
        super(...arguments);
        this.Icon = React.createElement(React.Fragment, null);
    }
    renderImg() {
        const { width, height, src, background, children, objectFit, className, onClick, alt } = this.props;
        const style = Object.assign({}, this.props.style);
        if (width)
            style.width = width;
        if (height)
            style.width = height;
        if (background) {
            return (React.createElement("div", { className: classNames(["rb-image-container", className]), onClick: onClick, style: Object.assign({}, style, { backgroundImage: src ? `url(${src})` : undefined }) }, children));
        }
        else {
            const classes = {
                "rb-image-cover": objectFit === "cover",
                "rb-image-contain": objectFit === "contain"
            };
            if (className)
                classes[className] = true;
            return (React.createElement("img", { className: classNames(classes), src: src, alt: alt, style: style, onClick: onClick }));
        }
    }
    render() {
        const { src } = this.props;
        if (src) {
            return this.renderImg();
        }
        else {
            return this.Icon;
        }
    }
}
RbImage.defaultProps = {
    src: "",
    objectFit: "cover"
};
export default RbImage;
